* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Jost', sans-serif;
}

.leaflet-container {
  width: 100wh; 
  height: 150vh;
  z-index: 0;
}

.dropdown-toggle::after {
  display: none !important; 
}